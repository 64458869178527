<template>
  <section class="section">
    <div class="container">
      <div class="login-panel">
        <div class="heading text-center">
          <h1>Please verify your email address</h1>
        </div>
        <div class="verify-message  text-center mt-2 mb-4">
          <p v-if="!verificationError">
            <strong>A verification email has been sent to your email account.<br> Please check your inbox</strong>
          </p>
          <p v-else>
            <strong>Your email could not be verified.<br> Please request a new verification below.</strong>
          </p>
          <p>We need you to verify your email address before you can use our platform.<br> Once you have verified please login.</p>
          <button
            id="back_out_button"
            class="btn btn-lg btn-primary"
            @click.prevent="backToLogin"
          >
            Go to Login
          </button>
        </div>

        <hr>

        <div class="resend-form text-center mt-1">
          <p
            v-if="!verificationError"
            class="mt-1"
          >
            If you have not received an email, please check your SPAM folder.<br> Alternatively you can request a new one below.
          </p>

          <form
            class="form-horizontal text-center mt-1"
            @submit.prevent="requestNewVerification"
          >
            <fieldset call="form-group">
              <div
                v-if="isError"
                class="form-error-message text-center"
              >
                Email address must be in a valid form.
              </div>
              <label class="mr-2">
                Request new verification email
                <input
                  id="email"
                  ref="email"
                  v-model="newEmail"
                  type="email"
                  class="form-control"
                  placeholder="Enter Email"
                  :disabled="!verificationError"
                >
              </label>
              <button
                type="submit"
                class="btn btn-lg btn-secondary"
              >
                Send
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {authStore} from '@/store/__STORE_auth';
export default {
  name: 'NeedToVerifyEmail',
  props: {
    email: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      isError: false,
      newEmail: '',
      // eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  computed: {
    verificationError() {
      return this.error === 'verificationFailed';
    },
  },
  mounted() {
    this.newEmail = this.email;
  },
  methods: {
    requestNewVerification() {
      this.isError = false;
      if (this.reg.test(this.newEmail)) {
        const payload = {
          email: this.newEmail,
        };
        authStore.resendVerifyEmail(payload)
            .then(() => {
              this.$toast.success('Email sent, please verify before logging in!');
              this.$router.push({name: 'login'});
            })
            .catch((err) => {
              if (err.response.status == 400) {
                this.$toast.success('Your email has already been verified, please login.');
                this.$router.push({name: 'login'});
              } else if (err.response.status == 404) {
                this.$toast.error('No user found with that email, please check it is correct and try again.');
              } else {
                this.$toast.error('Something went wrong, please check that your email is correct.');
              }
            });
      } else {
        this.isError = true;
      }
    },
    backToLogin() {
      this.$router.push({name: 'login'});
    },
  },
};
</script>

<style lang="scss" scoped>
  .verify-message {
    strong {
      font-size: 1.8rem;
    }
  }

  .resend-form {
    fieldset {
      align-items: flex-end;
      display: flex;
      flex-wrap: nowrap;

      label {
        flex: 1 0;
        margin-bottom: 0;
        text-align: left;

        input {
          margin-top: 1rem;
        }
      }

      button {
        flex: 0 1;
        white-space: nowrap;
      }
    }
  }
</style>
